'use client';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SlowMo } from "gsap/EasePack";
import { useRef } from "react";

import Container from "../../../components/core/container";
import Section from "../../../components/core/section";

import { Heading } from "@/components/core/heading";
import Text from "@/components/core/text";
import { useThemeColors } from "@/lib/colors";
import { Button } from "@/components/core/button";
import { AssetImage } from "@/models/asset";
import { useRouter } from "next/navigation";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SlowMo);

interface Props {
    title: string | null,
    content: string | null,
    background_color: string,
    background_image: AssetImage
}

export default function InitiativeCTA({ background_color, content, ...props}: Props) {
    const router = useRouter();
    
    const container = useRef<HTMLDivElement>(null);
    const card = useRef<HTMLDivElement>(null);
    
    const tl = useRef<gsap.core.Timeline>();

    const colors = useThemeColors(background_color);

    const gradientStops: { [key:string]: string } = {
        primary: "from-primary",
        secondary: "from-secondary",
        tertiary: "from-tertiary"
    }

    const backgroundColor: { [key: string]: string } = {
        primary: "lg:bg-tertiary",
        secondary: "lg:bg-tertiary",
        tertiary: "lg:bg-primary",
        "light-gray": "lg:bg-primary",
    }

    useGSAP(() => {

        tl.current = gsap.timeline({
            scrollTrigger: {
                trigger: container.current,
                start: "top 80%"
            }
        });

        gsap.set(card.current, { autoAlpha: 0, left: -500 });

        tl.current
            .to(card.current, { autoAlpha: 1, left: 0 })
    }, { scope: container, dependencies: [card] });

    return (
        <Section ref={container} overlay_color={background_color} overlay_opacity={80} background_image={props.background_image} background_position={props.background_image.focus_css} background_mix="soft-light" data-cursor-text="Learn More">
            <Container height="screen" className="flex items-end lg:items-center">
                <div ref={card} className={`relative z-20 space-y-4 max-w-lg p-8 rounded-lg ${backgroundColor[background_color]}`}>
                    <Heading as="h2" color={colors.accent_text} content="Strengthening<br>our community" />
                    <Text color={colors.accent_text}>
                        <p>We all recognize the need for more positivity in the world, and our contribution is to offer painless formation services starting at $0, mentorship for young entrepreneurs, and assistance for small businesses and nonprofits.</p>
                    </Text>
                    <Button label="Learn More" theme_color={colors.accent} href="/strengthening-communities"/>
                </div>
            </Container>
            <div className={`visible lg:invisible absolute top-0 left-0 z-10 w-full h-full bg-gradient-to-t ${gradientStops[colors.accent]}`}></div>
        </Section>
    )
}