'use client';

import { gsap } from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { TextPlugin } from "gsap/all";
import { useRef, useState, useEffect } from 'react';
import Section from "../core/section";
import Container from "../core/container";
import Text from "../core/text";
import { colorToHex, useThemeColors } from "@/lib/colors";
import './home.css'

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(TextPlugin);

interface Props {
  title: string | null,
  content: string | null
}

export default function HomeAboutContent(props: Props) {
  const container = useRef<HTMLDivElement>(null);

  const gsapContext = useRef<gsap.Context>();
  const tl = useRef<gsap.core.Timeline>();
  const tl2 = useRef<gsap.core.Timeline>();
  const tl3 = useRef<gsap.core.Timeline>();

  const [switchWords, setSwitchWords] = useState(false);
  const [selectedWords, setSelectedWords] = useState(["audacious","ambitious"]);


  const colors = useThemeColors("primary", true);
  const transitionColors = useThemeColors("light-gray", true);

  const style = {
    "--accent-color": colorToHex("primary"),
    "--accent-text-color": colorToHex("white")
  } as React.CSSProperties;
  

  // We approach every projects with
  // wonder fueled by a boundless
  // spirit of inquisitiveness.
  // We are a multi - disciplinary creative studio that specializes in helping small businesses and nonprofit organizations create a strong brand message through creative design and technical development

  useGSAP((context) => {
    gsapContext.current = context;

    const hero = document.body.querySelector("#home-hero");
    const heroBackground: HTMLElement | null = document.body.querySelector("#home-hero #home-hero-background");

    const heroRect = hero!.getBoundingClientRect();
    const containerRect = container.current!.getBoundingClientRect();

    const refresh = () => {
      const height = heroRect.height + containerRect.height;

      heroBackground!.style.height = `${height + 100}px`;
    }

    var text = ".about-text";

    tl.current = gsap.timeline({
      scrollTrigger: {
        trigger: hero,
        start: "bottom bottom",
        endTrigger: container.current,
        end: "bottom 60%",
        scrub: true
      }
    });

    tl2.current = gsap.timeline({
      scrollTrigger: {
        trigger: hero,
        start: "top bottom",
        endTrigger: text,
        end: "top 60%",
        scrub: true
      }
    });

    gsap.set(text, { autoAlpha: 0, y: 200, color: colors.text });

    tl.current
      //.from(heroBackground, { background: colorToHex("primary") })
      .to(heroBackground, { background: transitionColors.background })
      .to(hero!.querySelector("#home-hero-cta"), { color: transitionColors.accent, })


    tl2.current
      .to(text, { autoAlpha: 1, y: 0, color: transitionColors.text}, "<")
      .eventCallback("onComplete", () => { setSwitchWords(true) });

    context.add("onWordChange", (words: [string, string]) => {
      tl3.current = gsap.timeline();

      tl3.current.to("span.word:nth-of-type(1)", {
        duration: 0,
        text: {
          value: words[0],
        },
      })
      .to("span.word:nth-of-type(2)", {
        duration: 0,
        text: {
          value: words[1],
        },
      }, "<")
        // gsap.set("span", { autoAlpha: 0, scale: 0, })
        // gsap.to("span", { autoAlpha: 1, scale: 1, duration: .4, stagger: .2 })
    })

    refresh();

    window.addEventListener("resize", refresh);

    return () => window.removeEventListener("resize", refresh);

  }, { dependencies: [] });

  useEffect(() => {

    if (!switchWords) return;

    const words = [
      "audacious",
      "ambitious",
      "bold",
      "dreamer",
      "ingenious",
      "curious",
      "innovative",
      "visionary",
      "trailblazing",
      "pioneering"
    ]

    const shuffle = (array: string[]) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }

      return array;
    }

    const getRandomWord = () => {
      
      const shuffled = shuffle([...words]);
      const [randomString1, randomString2] = shuffled.slice(0, 2);

      return [randomString1, randomString2]; 
    }

    const timerId = setInterval(() => {
      const words = getRandomWord();
      setSelectedWords([words[0], words[1]]);
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, [switchWords]);

  useEffect(() => {
    gsapContext.current && gsapContext.current.onWordChange(selectedWords);
  }, [selectedWords])

  return (
    <Section id="about" ref={container} className="relative z-10" style={style}>
      <Container center={true} className="pt-64">
        <div className="content space-y-4 flex flex-col justify-center min-h-screen lg:min-h-[1500px] xl:min-h-[1500px] 2xl:min-h-[2500px]">
          <Text className="about-text text-content leading-[1.1]" size="title">
            We are a design and branding studio for the <span className="word">audacious</span> and the <span className="word">ambitious</span>, like you.
          </Text>
          <div className="grow"></div>
        </div>
      </Container>
    </Section>
  )
}